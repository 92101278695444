import { useEffect, useRef } from 'react';

const useInterval = (callback: Function, delay: number) => {
    const savedCallback = useRef({});

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const handler = (...args: any[]) => {
            if (typeof savedCallback.current === 'function') {
                const currentCallback = savedCallback.current as Function;

                currentCallback(...args);
            } else {
                // eslint-disable-next-line no-console
                console.error('useInterval found an undefined function');
            }
        };

        if (delay !== null) {
            const id = setInterval(handler, delay);

            return () => clearInterval(id);
        }
    }, [delay]);
};

export default useInterval;
