import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Box, Heading, Stack, Text, ScaleFade, Image } from '@chakra-ui/react';

import { ICallData, ConfigJsonModel } from '../types';
import alarm from '../assets/alarm.mp3';
import { EntityConfigContext } from '../context/EntityConfigContext';
import useImage from '../hooks/useImage';
import './LastCalled.css';

export interface LastCalledProps {
    lastCall: ICallData | null | undefined;
}

const lastCallAlarm: HTMLAudioElement = new Audio(alarm);

const playAlarm = (alarm: HTMLAudioElement) => {
    alarm.currentTime = 0;
    let playPromise = alarm.play();

    if (playPromise !== undefined) {
        playPromise.catch(() => {
            // User need to interact first!
        });
    }
};

const LastCalled = ({ lastCall }: LastCalledProps) => {
    const [id, setId] = useState(-1);
    const [patient, setPatient] = useState('');
    const [doctor, setDoctor] = useState('');
    const [place, setPlace] = useState('');
    const [triageName, setTriageName] = useState('');
    const [triageColorCode, setTriageColorCode] = useState('');
    const [isAnimationRunning, setIsAnimationRunning] = useState(false);
    const EntityConfig: ConfigJsonModel = useContext(EntityConfigContext);
    const { image }: any = useImage(EntityConfig?.ultimoLlamado?.logo);

    useEffect(() => {
        setIsAnimationRunning(false); // Reset animation
        playAlarm(lastCallAlarm); // Play alarm
        setTimeout(() => {
            setId(lastCall?.id || -1);
            setPatient(lastCall?.patient || '');
            setDoctor(lastCall?.doctor || '');
            setPlace(lastCall?.place || '');
            setTriageName(lastCall?.triageCategory?.name || '');
            setTriageColorCode(lastCall?.triageCategory?.colorCode || '');
            setIsAnimationRunning(true);
        }, 600);
    }, [lastCall]);

    return (
        <Fragment>
            <ScaleFade in={isAnimationRunning} initialScale={0.8}>
                <Stack
                    key={id} //Necessary to restart animation
                    alignItems="center"
                    bg="white"
                    borderRadius="10px"
                    boxShadow="md"
                    className="blink"
                    direction="row"
                    flex={1}
                    justifyContent="space-between"
                    paddingY={30}
                    pe={45}
                    spacing={6}
                >
                    {lastCall ? (
                        <Fragment>
                            <Stack spacing={4}>
                                <Box
                                    bg={EntityConfig?.ultimoLlamado?.labelColor}
                                    borderRadius="10px"
                                    color="white"
                                    fontSize={22}
                                    ml="30px"
                                    paddingX={5}
                                    paddingY={1}
                                    size="lg"
                                    width="fit-content"
                                >
                                    <Text casing="uppercase" fontWeight={500}>
                                        Último llamado
                                    </Text>
                                </Box>
                                <Stack alignItems="baseline" paddingLeft="30px">
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        justify="space-between"
                                    >
                                        <Heading as="h1" fontSize={36} size="2xl">
                                            {patient.toUpperCase()}
                                        </Heading>
                                        <Stack paddingLeft="10px">
                                            <Box
                                                bg={triageColorCode}
                                                borderRadius="10px"
                                                color="white"
                                                fontSize={23.47}
                                                paddingX={5}
                                                paddingY={1}
                                                width="-webkit-max-content"
                                            >
                                                <Text>{triageName}</Text>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                    <Text
                                        casing="uppercase"
                                        color="#00988D"
                                        fontSize={28}
                                        fontWeight={500}
                                    >
                                        {doctor}
                                    </Text>
                                    <Text fontSize={34} fontWeight={500}>
                                        {place}
                                    </Text>
                                </Stack>
                            </Stack>
                            {EntityConfig?.ultimoLlamado?.logo && (
                                <Image
                                    alt="Company logo"
                                    pr="18px"
                                    src={image}
                                    w={EntityConfig?.ultimoLlamado?.widthLogo}
                                />
                            )}
                        </Fragment>
                    ) : (
                        <Heading ml="22px">No hay llamados recientes</Heading>
                    )}
                </Stack>
            </ScaleFade>
        </Fragment>
    );
};

export default LastCalled;
