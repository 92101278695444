import React from 'react';
import { connect } from 'react-redux';
import { Progress } from '@chakra-ui/react';

import { IConnectionStatus } from '../types';

interface ConnectionStatusProps {
    connectionStatus: IConnectionStatus;
}

const WARNING_STATUS = ['reconnect', 'close', 'offline'];

const isWarnStatus = (status: string): boolean => WARNING_STATUS.indexOf(status) > -1;

const ConnectionStatus = ({ connectionStatus }: ConnectionStatusProps) =>
    isWarnStatus(connectionStatus?.type) ? (
        <Progress isIndeterminate colorScheme="green" size="xs" />
    ) : (
        <span />
    );

const mapStateToProps = (state: { calls: { connectionStatus: IConnectionStatus } }) => {
    return {
        connectionStatus: state?.calls?.connectionStatus,
    };
};

export default connect(mapStateToProps)(ConnectionStatus);
