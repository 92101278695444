import { createAction } from '@reduxjs/toolkit';

import { ICallData, IConfig } from '../types';

import {
    ADD,
    CLEAN_CALLS,
    CONNECT,
    SET_CLIENT,
    END_CONNECTION,
    FOUND_OTHER_SECTOR,
    SET_CONNECTION_ENDED,
    SET_CONFIG,
    SUBMIT_CONFIG,
    SET_CONFIG_LOCAL_STORAGE,
    SET_CONNECTION_STATUS,
    SUBMIT_CONFIG_AND_NAVIGATE,
} from './actions';

export const addCall = createAction<ICallData>(ADD);
export const cleanCalls = createAction<Date>(CLEAN_CALLS);

export const foundOtherSector = createAction<any>(FOUND_OTHER_SECTOR);

export const mqttConnect = createAction(CONNECT);

export const setClient = createAction<any>(SET_CLIENT);

export const endConnection = createAction(END_CONNECTION);

export const setConnectionEnded = createAction(SET_CONNECTION_ENDED);

export const setConfig = createAction<IConfig>(SET_CONFIG);

export const submitConfig = createAction<IConfig>(SUBMIT_CONFIG);

export const submitConfigAndNavigate = createAction<any>(SUBMIT_CONFIG_AND_NAVIGATE);

export const setConfigLocalStorage = createAction<IConfig>(SET_CONFIG_LOCAL_STORAGE);

export const setConnectionStatus = createAction<any>(SET_CONNECTION_STATUS);
