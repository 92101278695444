import { CONNECT, END_CONNECTION } from '../actions/actions';
import { addCall, setClient, setConnectionEnded, setConnectionStatus } from '../actions';
import { ICallData } from '../types';
import { connect, onMessage, onEvent } from '../utils/mqtt';

export function sectorFilterMiddleware() {
    return function (next: any) {
        return function (action: any) {
            // tg-255 se quita el filter
            return next(action);
        };
    };
}

export function mqttMiddleware({ getState, dispatch }: any) {
    return function (next: any) {
        return function (action: any) {
            if (action.type === CONNECT) {
                const { topic, server, port, qos, cleanSession, protocol, username, password } =
                    getState().config;

                const url = port?.length ? `${server}:${port}` : server;

                let mqttClient = connect(
                    url,
                    topic,
                    qos,
                    cleanSession,
                    protocol,
                    username,
                    password,
                );

                onEvent((event: string) => dispatch(setConnectionStatus(event)), mqttClient);

                onMessage((call: ICallData) => dispatch(addCall(call)), mqttClient);

                return dispatch(setClient(mqttClient));
            }

            return next(action);
        };
    };
}

export function endMqttMiddleware({ dispatch }: any) {
    return function (next: any) {
        return function (action: any) {
            if (action.type === END_CONNECTION) {
                return dispatch(setConnectionEnded());
            }

            return next(action);
        };
    };
}
