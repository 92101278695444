import { useState, useEffect } from 'react';

export const useDate = (format: (date: Date) => string) => {
    const [today, setDate] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setDate(new Date());
        }, 60 * 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return format(today);
};
