import React from 'react';
import { Field, Form, Formik } from 'formik';
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Divider,
    ButtonGroup,
} from '@chakra-ui/react';
import './Config.css';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import {
    submitConfig as actionSubmitConfig,
    submitConfigAndNavigate as actionSubmitConfigAndNavigate,
} from '../actions';
import { IConfig } from '../types';

const Config = ({ submitConfig, submitConfigAndNavigate, config }: any) => {
    let history = useHistory();

    const submitFormAndNavigate = (isValid: boolean, values: any) => {
        if (isValid) submitConfigAndNavigate({ values, history });
    };

    return (
        <React.Fragment>
            <Stack
                alignSelf="center"
                bg="white"
                borderRadius="10px"
                boxShadow="lg"
                paddingX={6}
                paddingY={4}
                width="50%"
            >
                <Formik
                    initialValues={config}
                    onSubmit={(values) => {
                        submitConfig(values);
                    }}
                >
                    {({ values, isValid }) => (
                        <Form className="form" id="form">
                            <Stack minWidth="500px" spacing={8}>
                                <Field name="server">
                                    {({ field }: any) => (
                                        <FormControl isRequired borderColor="gray.300">
                                            <FormLabel htmlFor="server">Server</FormLabel>
                                            <Input
                                                {...field}
                                                id="server"
                                                placeholder="Server para escuchar los mensajes con los llamados"
                                            />
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="port">
                                    {({ field }: any) => (
                                        <FormControl isRequired borderColor="gray.300">
                                            <FormLabel htmlFor="port">Port</FormLabel>
                                            <Input
                                                {...field}
                                                id="port"
                                                placeholder="Puerto al cual se envían los mensajes con los llamados"
                                            />
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="topic">
                                    {({ field }: any) => (
                                        <FormControl borderColor="gray.300">
                                            <FormLabel htmlFor="topic">Tópico</FormLabel>
                                            <Input
                                                {...field}
                                                id="topic"
                                                placeholder="Tópico para escuchar mensajes"
                                            />
                                        </FormControl>
                                    )}
                                </Field>

                                <Divider />

                                <Field name="username">
                                    {({ field }: any) => (
                                        <FormControl borderColor="gray.300">
                                            <FormLabel htmlFor="username">Usuario</FormLabel>
                                            <Input {...field} id="username" placeholder="Usuario" />
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="password">
                                    {({ field }: any) => (
                                        <FormControl borderColor="gray.300">
                                            <FormLabel htmlFor="password">Contraseña</FormLabel>
                                            <Input
                                                {...field}
                                                id="password"
                                                placeholder="*********"
                                                type="password"
                                            />
                                        </FormControl>
                                    )}
                                </Field>

                                <Divider />

                                <Field name="video">
                                    {({ field }: any) => (
                                        <FormControl borderColor="gray.300">
                                            <FormLabel htmlFor="video">Video link</FormLabel>
                                            <Input {...field} id="video" placeholder="Video link" />
                                        </FormControl>
                                    )}
                                </Field>

                                <Divider />

                                <ButtonGroup spacing={4}>
                                    <Button
                                        colorScheme="teal"
                                        flex={1}
                                        textTransform="uppercase"
                                        type="submit"
                                        variant="outline"
                                    >
                                        Guardar
                                    </Button>
                                    <Button
                                        colorScheme="teal"
                                        flex={1}
                                        textTransform="uppercase"
                                        type="button"
                                        onClick={() => submitFormAndNavigate(isValid, values)}
                                    >
                                        Guardar y Confirmar
                                    </Button>
                                </ButtonGroup>
                            </Stack>
                        </Form>
                    )}
                </Formik>
            </Stack>
        </React.Fragment>
    );
};

const mapStateToProps = (state: { calls: any; config: IConfig }) => {
    return {
        config: state.config,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        submitConfig: (payload: any) => dispatch(actionSubmitConfig(payload)),
        submitConfigAndNavigate: (payload: any) => dispatch(actionSubmitConfigAndNavigate(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Config);
