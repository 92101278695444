import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { endMqttMiddleware, mqttMiddleware, sectorFilterMiddleware } from '../middleware';
import callsReducer from '../reducers/calls';
import configReducer from '../reducers/config';
import {
    setConfigLocalStorageMiddleware,
    setConfigMiddleware,
    submitConfigAndNavigateMiddleware,
    submitConfigMiddleware,
} from '../middleware/config';

const middleware = [
    ...getDefaultMiddleware({ serializableCheck: false }),
    setConfigMiddleware,
    setConfigLocalStorageMiddleware,
    submitConfigMiddleware,
    submitConfigAndNavigateMiddleware,
    sectorFilterMiddleware,
    mqttMiddleware,
    endMqttMiddleware,
];

const store = configureStore({
    reducer: {
        calls: callsReducer,
        config: configReducer,
    },
    middleware,
});

export default store;
