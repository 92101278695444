import React, { memo } from 'react';
import { AspectRatio, Box } from '@chakra-ui/react';
import './VideoComponent.css';

const VideoComponent = memo((props: { link: string }) => {
    return (
        <Box className="border-video" width="50%">
            <AspectRatio height="100%" ratio={16 / 9} width="100%">
                <iframe src={props.link} />
            </AspectRatio>
        </Box>
    );
});

VideoComponent.displayName = 'VideoComponent';

export default VideoComponent;
