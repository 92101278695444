import { createStandaloneToast } from '@chakra-ui/react';

import {
    SET_CONFIG,
    SET_CONFIG_LOCAL_STORAGE,
    SUBMIT_CONFIG,
    SUBMIT_CONFIG_AND_NAVIGATE,
} from '../actions/actions';
import { setConfig, setConfigLocalStorage } from '../actions';
import { saveState } from '../utils/localStorage';

export function submitConfigMiddleware({ dispatch }: any) {
    return function (next: any) {
        return function (action: any) {
            if (action.type === SUBMIT_CONFIG) {
                return dispatch(setConfigLocalStorage(action.payload));
            }

            return next(action);
        };
    };
}

export function submitConfigAndNavigateMiddleware({ dispatch }: any) {
    return function (next: any) {
        return function (action: any) {
            if (action.type === SUBMIT_CONFIG_AND_NAVIGATE) {
                let { values, history } = action.payload;
                let nextAction = dispatch(setConfigLocalStorage(values));

                history.push('/turnos');

                return nextAction;
            }

            return next(action);
        };
    };
}

export function setConfigLocalStorageMiddleware({ dispatch }: any) {
    return function (next: any) {
        return function (action: any) {
            if (action.type === SET_CONFIG_LOCAL_STORAGE) {
                saveState(action.payload);

                return dispatch(setConfig(action.payload));
            }

            return next(action);
        };
    };
}

export function setConfigMiddleware() {
    return function (next: any) {
        return function (action: any) {
            const toast = createStandaloneToast();

            if (action.type === SET_CONFIG) {
                toast({
                    title: 'Configuración guardada',
                    description:
                        'La configuración de la aplicación ha sido guardada correctamente.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            }

            return next(action);
        };
    };
}
