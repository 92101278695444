export const ADD = 'ADD';
export const CLEAN_CALLS = 'CLEAN_CALLS';
export const FOUND_OTHER_SECTOR = 'FOUND_OTHER_SECTOR';
export const CONNECT = 'CONNECT';
export const SET_CLIENT = 'SET_CLIENT';
export const END_CONNECTION = 'END_CONNECTION';
export const SET_CONNECTION_ENDED = 'SET_CONNECTION_ENDED';
export const SET_CONFIG = 'SET_CONFIG';
export const SUBMIT_CONFIG = 'SUBMIT_CONFIG';
export const SET_CONFIG_LOCAL_STORAGE = 'SET_CONFIG_LOCAL_STORAGE';
export const SET_CONNECTION_STATUS = 'SET_CONNECTION_STATUS';
export const SUBMIT_CONFIG_AND_NAVIGATE = 'SUBMIT_CONFIG_AND_NAVIGATE';
