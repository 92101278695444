import { Redirect, Route, Switch } from 'react-router-dom';
import * as React from 'react';

import Turnero from './pages/Turnero';
import Config from './pages/Config';

const Router = () => {
    return (
        <Switch>
            <Route component={Turnero} path="/turnos" />
            <Route component={Config} path="/config" />
            <Redirect path="*" to="/turnos" />
        </Switch>
    );
};

export default Router;
