import { IConfig } from '../types';

export const CONFIG_KEY = 'config';

export const loadState = (): IConfig | undefined => {
    try {
        const serializedState = localStorage.getItem(CONFIG_KEY);

        if (serializedState === null) {
            return undefined;
        }

        const parsedState = JSON.parse(serializedState);

        return mapToIConfig(parsedState);
    } catch (err) {
        return undefined;
    }
};

const mapToIConfig = (state: any) => ({
    ...state,
    qos: Number(state.qos),
});

export const saveState = (state: any) => {
    const serializedState = JSON.stringify(state);

    localStorage.setItem(CONFIG_KEY, serializedState);
};
