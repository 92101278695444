import { createContext, useState, useContext } from 'react';
import * as React from 'react';

import { ConfigJsonModel } from '../types';

export const EntityConfigContext = createContext({} as ConfigJsonModel);
export const EntityConfigContextProvider = ({ children }) => {
    const [config, setConfig] = useState(null);

    React.useEffect(() => {
        const getDataFetch = async () => {
            const response = await fetch('./themeConfig.json');

            const data = await response.json();

            setConfig(data);
        };

        getDataFetch();
    }, []);

    return <EntityConfigContext.Provider value={config}>{children}</EntityConfigContext.Provider>;
};

export function useEntityConfig() {
    const context = useContext(EntityConfigContext);

    if (context === undefined) {
        throw new Error('Context must be used within a Provider');
    }

    return context;
}
