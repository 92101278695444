import * as React from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';

import Layout from './components/Layout';
import Router from './Router';
import { EntityConfigContextProvider } from './context/EntityConfigContext';

export const App = () => {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <EntityConfigContextProvider>
                <ChakraProvider theme={theme}>
                    <Layout>
                        <Router />
                    </Layout>
                </ChakraProvider>
            </EntityConfigContextProvider>
        </BrowserRouter>
    );
};
