import mqtt from 'mqtt';
import { MqttClient } from 'mqtt/types/lib/client';

import { ICallData, IMessage, Protocol } from '../types';

const MQTT_PREFIX = 'mqtt://';

export const connect = (
    brokerUrl: string,
    topic: string,
    qos: 0 | 1 | 2,
    cleanSession: boolean,
    protocol: Protocol,
    username: string,
    password: string,
) => {
    // eslint-disable-next-line
    console.info(`connect to ${brokerUrl}`, protocol);
    let client = mqtt.connect(`${MQTT_PREFIX}${brokerUrl}`, {
        clean: cleanSession,
        protocol,
        username,
        password,
    });

    client.on('connect', function () {
        // eslint-disable-next-line
        console.info(`Subscribe to ${topic}`);
        client.subscribe(topic, { qos: qos }, function () {});
    });

    return client;
};

export const onMessage = (callbackFn: (call: ICallData) => void, client: MqttClient) => {
    client?.on('message', (topic: string, message: Buffer) => {
        if (message) {
            // eslint-disable-next-line
            console.info(`Message arrive ${topic}`);
            const { type, data }: IMessage = JSON.parse(message.toString());

            // eslint-disable-next-line
            console.info(`Message parsed ${topic} [${type}]`, data);
            if (type === 'add') {
                callbackFn({ ...data, time: new Date() });
            }
        }
    });
};

export const onEvent = (callbackFn: (event: string) => void, client: MqttClient) => {
    client.on('connect', () => {
        // Emitted on successful (re)connection (i.e. connack rc=0).
        callbackFn('connect');
    });
    client.on('reconnect', () => {
        // Emitted when a reconnect starts.
        callbackFn('reconnect');
    });
    client.on('close', () => {
        // Emitted after a disconnection.
        callbackFn('close');
    });
    client.on('disconnect', (packet) => {
        // eslint-disable-next-line
        console.error('Error en la conexión', packet);
        // Emitted after receiving disconnect packet from broker. MQTT 5.0 feature.
        callbackFn('disconnect');
    });
    client.on('offline', () => {
        // Emitted when the client goes offline.
        callbackFn('offline');
    });
    client.on('error', (error) => {
        // eslint-disable-next-line
        console.error('Error en la conexión', error);
        // Emitted when the client cannot connect (i.e. connack rc != 0) or when a parsing error occurs.
        callbackFn('error');
    });
};

export const end = (client: MqttClient) => {
    client?.end(true);
};
